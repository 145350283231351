import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Dead from "./artifacts/contracts/dead.sol/CryptoIsDead.json";
import "./App.css";

//const INFURA_WEBSOCKET = process.env.REACT_APP_INFURA_WEBSOCKET;

function App() {
  const [buyer, setBuyer] = useState("");
  const [buyerAmount, setBuyerAmount] = useState("");
  const [tokensForReward, setTokensForReward] = useState("");
  const [previousBuyer, setPreviousBuyer] = useState("");
  const [previousBuyerAmount, setPreviousBuyerAmount] = useState("");
  const [account, setAccount] = useState("");
  const [contract, setContract] = useState("");
  const [hour, setHour] = useState("");
  const [previousHour, setPreviousHour] = useState("");

  const initConnection = async () => {
    const provider = new ethers.providers.WebSocketProvider(
      "wss://mainnet.infura.io/ws/v3/cd5a62dd66a648a284ba458390ecc74c"
    );

    const erc20 = new ethers.Contract(
      "0x3984478D878053Eea2b154bCDf916429d09bfBF9",
      Dead.abi,
      provider
    );

    const currentHour = await erc20.getHour();
    setHour(currentHour.toNumber());

    const previousHour = currentHour - 1;
    setPreviousHour(previousHour);

    const biggestBuyAddress = await erc20.biggestBuyer(currentHour);
    setBuyer(biggestBuyAddress);

    const previousBiggestAddress = await erc20.biggestBuyer(previousHour);
    setPreviousBuyer(previousBiggestAddress);

    const biggestBuyAmount = await erc20.biggestBuyerAmount(currentHour);
    const biggestBuyAmount1 = ethers.utils.formatUnits(biggestBuyAmount, 18);
    setBuyerAmount(Math.round(biggestBuyAmount1));

    const previousBuyAmount = await erc20.biggestBuyerAmount(previousHour);
    const previousBuyAmount1 = ethers.utils.formatUnits(previousBuyAmount, 18);
    setPreviousBuyerAmount(Math.round(previousBuyAmount1));

    setContract(
      new ethers.Contract(
        "0x3984478D878053Eea2b154bCDf916429d09bfBF9",
        Dead.abi,
        provider
      )
    );
  };

  useEffect(() => {
    initConnection();
    const provider = new ethers.providers.WebSocketProvider(
      "wss://mainnet.infura.io/ws/v3/cd5a62dd66a648a284ba458390ecc74c"
    );
    const erc20 = new ethers.Contract(
      "0x3984478D878053Eea2b154bCDf916429d09bfBF9",
      Dead.abi,
      provider
    );
    erc20.on("Transfer", (from, to, value) => {
      let info = {
        from: from,
        to: to,
        value: ethers.utils.formatUnits(value, 18),
      };
      console.log(info.value);
    });
  }, []);

  return (
    <div className="page">
      <section className="top">
        <h2>Biggest Buyer</h2>
        <div className="data">
          {buyerAmount > "0" ? (
            <>
              <div className="left">
                <h3>Wallet</h3>
                <p>{buyer}</p>
              </div>
              <div className="right">
                <h3>Amount Bought</h3>
                <p>{buyerAmount.toLocaleString()} $DEAD</p>
              </div>
            </>
          ) : (
            <>
              <div className="left">
                <h3>Wallet</h3>
                <p>--</p>
              </div>
              <div className="right">
                <h3>Amount Bought</h3>
                <p>--</p>
              </div>
            </>
          )}
        </div>
      </section>
      <section className="bottom">
        <h2>Previous Biggest Buyer</h2>
        <div className="data">
          {previousBuyerAmount > "0" ? (
            <>
              <div className="left">
                <h3>Wallet</h3>
                <p>{previousBuyer}</p>
              </div>
              <div className="right">
                <h3>Amount Bought</h3>
                <p>{previousBuyerAmount.toLocaleString()} $DEAD</p>
              </div>
            </>
          ) : (
            <>
              <div className="left">
                <h3>Wallet</h3>
                <p>--</p>
              </div>
              <div className="right">
                <h3>Amount Bought</h3>
                <p>--</p>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

export default App;
